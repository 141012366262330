import BioRhymeLight from "./BioRhyme-Light-hint-all.woff2";
import BioRhymeLightSubset from "./BioRhyme-Light-kern-latin.woff2";
import BioRhymeRegular from "./BioRhyme-Regular-hint-all.woff2";
import BioRhymeRegularSubset from "./BioRhyme-Regular-kern-latin.woff2";
import BioRhymeExpandedExtraBold from "./BioRhymeExpanded-ExtraBold-hint-all.woff2";
import BioRhymeExpandedExtraBoldSubset from "./BioRhymeExpanded-ExtraBold-kern-latin.woff2";
import BioRhymeExpandedRegular from "./BioRhymeExpanded-Regular-hint-all.woff2";
import BioRhymeExpandedRegularSubSet from "./BioRhymeExpanded-Regular-kern-latin.woff2";

const fontSubset = [
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeLightSubset, format: "woff2" }],
    descriptors: {
      weight: 300,
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeRegularSubset, format: "woff2" }],
    descriptors: {
      weight: 400,
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeExpandedRegular, format: "woff2" }],
    descriptors: {
      weight: 400,
      stretch: "expanded",
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeExpandedExtraBoldSubset, format: "woff2" }],
    descriptors: {
      weight: 800,
      stretch: "expanded",
    },
  },
];

const font = [
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeLight, format: "woff2" }],
    descriptors: {
      weight: 300,
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeRegular, format: "woff2" }],
    descriptors: {
      weight: 400,
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeExpandedRegularSubSet, format: "woff2" }],
    descriptors: {
      weight: 400,
      stretch: "expanded",
    },
  },
  {
    family: "BioRhyme",
    src: [{ url: BioRhymeExpandedExtraBold, format: "woff2" }],
    descriptors: {
      weight: 800,
      stretch: "expanded",
    },
  },
];

export { fontSubset, font };
