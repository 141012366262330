import React from "react";

import Box from "components/Box";
import Button from "components/Button";
import Icon from "components/Icon";
import Link from "components/Link";
import Span from "components/Span";

import { useCloseMenus, useNavigationBreakpoint } from "./context";

import List from "./List";
import FlyOutMenu from "./FlyOutMenu";

const style = {
  titleComplex: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  iconComplex: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  title: {},
  subtitle: {},
  link: {},
  icon: {},
};

const Item = ({
  action,
  href,
  icon,
  items,
  level,
  subtitle,
  title,
  to,
  type,
  ...props
}) => {
  const { isMobile } = useNavigationBreakpoint();
  const close = useCloseMenus();
  const themeProps = {
    __themeKey: "navigation",
    "data-nav-level": level || 0,
  };

  if (type === "separator") {
    return (
      <ListItem
        variant="item.separator"
        role="separator"
        {...themeProps}
        {...props}
      />
    );
  }

  let item = (
    <Span variant="item.title" __css={style.title} {...themeProps}>
      {title}
      {type === "dropdown" && (
        <React.Fragment>
          &thinsp;
          <Icon icon="chevron-down" />
        </React.Fragment>
      )}
    </Span>
  );

  if (subtitle) {
    item = (
      <Span
        variant="item.titleComplex"
        __css={style.titleComplex}
        {...themeProps}
      >
        {item}
        <Span variant="item.subtitle" __css={style.subtitle} {...themeProps}>
          {subtitle}
        </Span>
      </Span>
    );
  }

  if (icon) {
    item = (
      <Span
        variant="item.iconComplex"
        __css={style.iconComplex}
        {...themeProps}
      >
        <Icon icon={icon} variant="item.icon" sx={style.icon} {...themeProps} />
        {item}
      </Span>
    );
  }

  if (type === "dropdown") {
    if (isMobile) {
      return (
        <ListItem {...themeProps}>
          <Span variant="item.heading" {...themeProps}>
            {item}
          </Span>
          <List level={level || 1}>
            {items.map(item => (
              <Item {...item} />
            ))}
          </List>
        </ListItem>
      );
    }

    return (
      <FlyOutMenu as={ListItem} level={level} {...themeProps}>
        <FlyOutMenu.Button variant="item.link" {...themeProps} {...props}>
          {item}
        </FlyOutMenu.Button>
        <List level={level || 1}>
          {items.map(item => (
            <Item {...item} />
          ))}
        </List>
      </FlyOutMenu>
    );
  }

  if (type === "button") {
    return (
      <ListItem {...themeProps}>
        <Button variant="item.button" {...themeProps} {...props}>
          {item}
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem {...themeProps}>
      <Link
        to={to}
        href={href}
        onClick={() => close()}
        variant="item.link"
        {...themeProps}
        {...props}
      >
        {item}
      </Link>
    </ListItem>
  );
};

function ListItem(props) {
  return <Box as="li" variant="item" {...props} />;
}

export default Item;
