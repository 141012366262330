import createStore from "zustand";

const useStore = createStore(set => ({
  items: [],
  addItems: items => {
    const arr = Array.isArray(items) ? items : [items];
    set(prev => ({
      items: arr.concat(prev.items),
    }));
  },
  removeItems: items => {
    const arr = Array.isArray(items) ? items : [items];
    set(prev => ({
      items: arr.length > 0 ? prev.items.filter(o => !arr.includes(o.id)) : [],
    }));
  },
}));

function selectAddItems(state) {
  return state.addItems;
}

function selectFirstItem(state) {
  return state.items[0];
}

function selectRemoveItems(state) {
  return state.removeItems;
}

function selectItems(state) {
  return state.items;
}

function useAddNotification() {
  return useStore(selectAddItems);
}

function useCurrentNotification() {
  return useStore(selectFirstItem);
}

function useNotifications() {
  return useStore(selectItems);
}

function useRemoveNotification() {
  return useStore(selectRemoveItems);
}

export {
  useAddNotification,
  useCurrentNotification,
  useNotifications,
  useRemoveNotification,
};
