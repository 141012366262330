import React from "react";
import { Globals } from "@react-spring/web";

import useMatchMedia from "hooks/useMatchMedia";

const Accessibility = () => {
  const handleReducedMotion = React.useCallback(matches => {
    Globals.assign({ skipAnimation: matches });
  }, []);

  useMatchMedia("(prefers-reduced-motion: reduce)", {
    default: true,
    callback: handleReducedMotion,
  });

  return null;
};

export default Accessibility;
