const item = {
  "&[data-nav-level='0']": {
    mb: [3.5, 0],
    bg: ["white", "transparent"],
    borderRadius: ["md", 0],
    overflow: ["hidden", "visible"],
  },

  heading: {
    display: "block",
    px: 3.5,
    py: 3,
    color: "gray.300",
  },

  link: {
    display: "block",
    px: 3,
    py: 2,
    border: "none",
    borderRadius: [0, "sm"],
    color: "inherit",
    textDecoration: "none",
    fontWeight: 400,
    ":focus": {
      position: "relative",
      color: "focus",
    },
    "@media (hover:hover)": {
      "&:hover": {
        bg: "gray.100",
      },
      "&[data-nav-level='0']:hover": {
        bg: "brick.50",
      },
    },
    "&[data-nav-level='0']": {
      px: [3.5, 3],
      py: [3, 2],
    },
    "&:not([data-nav-level='0'])": {
      px: 3.5,
      py: 2,
    },
  },

  button: {
    variant: "buttons.primary",
    my: 2,
    py: 2,
    px: 3,
    "li:last-of-type > &": {
      mb: 0,
    },
    ":not([data-nav-level='0'])": {
      width: "100%",
      py: 2.5,
    },
  },

  separator: {
    my: 2.5,
    "& + li": {
      mt: -2.5,
      pt: 2.5,
    },
    "& ~ li": {
      mx: [0, -3],
      px: 3,
      bg: "turquoise.50",
    },
    "& ~ li:last-of-type": {
      mb: [0, -3],
      pb: 3,
      borderBottomRadius: t => t.radii.default,
      borderBottomLeftRadius: "default",
      borderBottomRightRadius: "default",
    },
    "& ~ li a": {
      color: "turquoise.900",
      mx: [-3, 0],
    },
    "@media (hover:hover)": {
      "& ~ li a:hover": {
        bg: "turquoise.100",
      },
    },
  },

  title: {
    lineHeight: 1.2,
    "&:nth-last-of-type(2)": {
      fontWeight: 700,
    },
  },

  subtitle: {
    fontSize: 0,
    color: "textMuted",
  },

  icon: {
    mr: 3,
    color: "inherit",
    fontSize: 3,
  },
};

const list = {
  main: {},

  default: {
    display: "flex",
    flexFlow: "column nowrap",
    // my: 3,
  },

  flyOut: {
    display: "flex",
    flexFlow: "column nowrap",
    minWidth: 300,
    p: 3,
    fontSize: 1,
  },
};

const flyOutMenu = {
  position: "relative",
  zIndex: "popover",
  bg: "white",
  boxShadow: "xl",
  borderRadius: "default",
  "&[data-expanded='false']": {
    display: "none",
  },
};

const toggle = {
  position: "relative",
  p: 3,
  border: "none",
  transition: "500ms ease-in-out",
  transitionProperty: "color",
  color: "link",
  outline: "none",
  textTransform: "uppercase",
  fontSize: 1,
  bg: "transparent",

  "&[data-focused='true']": {
    variant: "focus",
  },

  "&[aria-expanded='true']": {},
};

export { flyOutMenu, item, list, toggle };
