import * as biorhyme from "./biorhyme";
import * as lushootseed from "./lushootseed-sulad";

const { fontSubsets, fonts } = [biorhyme, lushootseed].reduce(
  (obj, { fontSubset, font }) => {
    obj.fontSubsets = [...(obj.fontSubsets || []), ...(fontSubset || [])];
    obj.fonts = [...(obj.fonts || []), ...(font || [])];
    return obj;
  },
  {}
);

const fontFaceDeclarations = fontSubsets.map(
  ({ family, src, descriptors }) => ({
    fontFamily: family,
    src: src
      .map(({ url, format }) => `url(${url}) format('${format}')`)
      .join(", "),
    fontWeight: descriptors.weight,
    fontStyle: descriptors.style,
    fontStretch: descriptors.stretch,
    fontDisplay: "swap",
  })
);

export { fontFaceDeclarations, fonts, fontSubsets };
