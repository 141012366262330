const styles = {
  a: {
    color: "link",
    ":hover": {
      color: "link.900",
    },
  },

  blockquote: {
    color: "textMuted",
    mb: 3,
    px: 3,
    borderLeft: "4px solid",
    borderColor: "gray.200",
  },

  h1: {
    mb: 3,
    fontFamily: "heading",
    fontSize: 4,
    fontWeight: "normal",
  },

  h2: {
    mb: 3,
    fontFamily: "heading",
    fontSize: 3,
    fontWeight: 400,
  },

  h3: {
    mb: 3,
    fontFamily: "body",
    fontSize: 3,
    fontWeight: 700,
  },

  hr: {
    border: "none",
    bg: "gray.200",
    width: "100%",
    height: 1,
    display: "block",
    my: 3,
  },

  p: {
    mt: 0,
    mb: 3,
  },
};

export default styles;
