import React from "react";
import Popover from "@reach/popover";

import { useFlyOutProps, FlyOutContextProvider } from "./context";

import Button from "components/Button";
import Box from "components/Box";

const FlyOutMenu = ({ ...props }) => (
  <FlyOutContextProvider level={props.level}>
    <Box sx={{ position: "relative" }} {...props} />
  </FlyOutContextProvider>
);

FlyOutMenu.Button = FlyOutButon;
FlyOutMenu.List = FlyOutList;

export default FlyOutMenu;

function FlyOutButon(props) {
  const { getButtonProps } = useFlyOutProps();
  return <Button __themeKey="navigation" {...getButtonProps()} {...props} />;
}

function FlyOutList({ children, ...props }) {
  const { isExpanded, getPopoverProps, getListProps } = useFlyOutProps();

  if (isExpanded) {
    return (
      <Popover
        as={Box}
        sx={{ zIndex: "menu" }}
        {...getListProps()}
        {...getPopoverProps()}
        variant="flyOutMenu"
        __themeKey="navigation"
        {...props}
      >
        {children}
      </Popover>
    );
  }

  return null;
}
