function withHalfSteps(arr) {
  const a = arr.slice();
  for (let i = 0; i + 1 < a.length; i++) {
    a[`${i}.5`] = (a[i] + a[i + 1]) / 2;
  }

  return a;
}

function withAliases(arr, obj) {
  return Object.entries(obj).reduce(
    (a, [k, v]) => Object.assign(a, { [k]: v }),
    arr.slice()
  );
}

const breakpointsScale = ["36em", "54em"];

const breakpointsAliases = {
  md: breakpointsScale[0],
  lg: breakpointsScale[1],
  navbar: breakpointsScale[0],
};

export const breakpoints = withAliases(breakpointsScale, breakpointsAliases);

const systemFonts = [
  "-apple-system",
  "'BlinkMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Helvetica Neue'",
  "'Arial'",
  "'Noto Sans'",
  "sans-serif",
  "'Apple Color Emoji'",
  "'Segoe UI Emoji'",
  "'Segoe UI Symbol'",
  "'Noto Color Emoji'",
];

export const fonts = {
  body: ["'Public Sans'"].concat(systemFonts).join(", "),
  heading: ["'BioRhyme'"].concat(systemFonts).join(", "),
  lushootseed: ["'Lushootseed Sulad'"].concat(systemFonts).join(", "),
};

export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96];

export const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
};

const gray = {
  50: "#FBFAF9",
  100: "#F7F5F3",
  200: "#D9D5D1",
  300: "#BCB6AF",
  400: "#A0978F",
  500: "#857A70",
  600: "#6B5E51",
  700: "#524335",
  800: "#3A2A1A",
  900: "#241200",
};

const turquoise = {
  50: "#E8EFF0",
  100: "#C4DCDE",
  500: "#3E9AAC",
  900: "#09536C",
};

const brick = {
  50: "#F8E9E2",
  100: "#ECBBA2",
  500: "#DD5835",
  900: "#851A05",
};

const lime = {
  100: "#ECFFBF",
  500: "#BBEC27",
  900: "#586501",
};

const fuchsia = {
  50: "#FAEBF5",
  100: "#ECACD9",
  500: "#CC1E98",
  900: "#610545",
};

const violet = {
  50: "#F1EEF7",
  100: "#C0B1DD",
  500: "#8160C7",
  900: "#2B1769",
};

for (const scale of [gray, turquoise, brick, lime, fuchsia, violet]) {
  scale.__default = scale["500"];
}

export const colors = {
  primary: turquoise,
  background: gray["100"],
  text: gray["700"],
  textMuted: gray["500"],
  textFaint: gray["300"],
  link: brick,
  focus: brick,
  focusRing: brick["100"],
  //
  lime,
  violet,
  fuchsia,
  brick,
  turquoise,
  gray,
};

export const zIndices = {
  menu: 1500,
  modal: 2000,
};

const spaceScale = [0, 4, 8, 16, 32, 64, 128, 256, 512];

export const space = withHalfSteps(spaceScale);

export const sizes = {
  xs: 192,
  sm: 384,
  md: 576,
  lg: 768,
  xl: 1152,
  xxl: 1440,
};

const shadowsScale = [
  // xs
  "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  // sm
  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  // md
  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  // lg
  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  // xl
  "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
];

const shadowsAliases = {
  xs: shadowsScale[0],
  sm: shadowsScale[1],
  md: shadowsScale[2],
  lg: shadowsScale[3],
  xl: shadowsScale[4],
  focus: `0 0 0 3px ${colors.focusRing}`,
};

export const shadows = withAliases(shadowsScale, shadowsAliases);

export const radii = {
  default: 8,
  lg: 24,
  md: 8,
  sm: 3,
};

export const lineHeights = {
  body: 1.5,
  heading: 1.25,
};
