import React from "react";
import { Helmet } from "react-helmet";

import useLoadFonts from "hooks/useLoadFonts";

import GlobalStyles from "components/GlobalStyles";

function Head() {
  const { preloadLinks } = useLoadFonts();

  return (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        {preloadLinks}
        <style>
          {`:root {
            --reach-accordion: 1;
            --reach-dialog: 1;
            --reach-listbox: 1;
            --reach-menu-button: 1;
          }`}
        </style>
      </Helmet>
    </React.Fragment>
  );
}

export default Head;
