const banner = {
  variant: "links.banner",
  border: "none",
  borderRadius: 0,
};

const inline = {
  verticalAlign: "baseline",
  mb: 1,
};

const link = {
  padding: 0,
  border: "none",
  borderRadius: 0,
  variant: "styles.a",
};

const plain = {
  px: 0,
  py: 0,
  border: "none",
  borderRadius: 0,
  ":hover": {
    textDecoration: "underline",
  },
};

const primary = {
  borderColor: "turquoise.500",
  bg: "turquoise.500",
  color: "white",
  ":active": {
    transform: "translateY(1px)",
  },
  ":not(:disabled):not([aria-disabled]):hover": {
    bg: "turquoise.900",
    borderColor: "turquoise.900",
  },
};

const secondary = {
  bg: "white",
  borderColor: "white",
  ":active": {
    transform: "translateY(1px)",
  },
  ":hover": {
    bg: "gray.100",
    borderColor: "gray.100",
  },
};

const submit = {
  display: "block",
  px: 4,
  py: 3,
  variant: "buttons.primary",
  width: "100%",
};

const sm = {
  py: 2,
  px: 3,
};

export { banner, inline, link, plain, primary, secondary, submit, sm };
