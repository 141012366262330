import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { styled } from "style";

import Icon from "components/Icon";

const StyledButton = styled("button", {
  defaultVariant: "primary",
  forwardProps: ["activeClassName", "state", "to"],
  themeKey: "buttons",
})({
  display: "inline-block",
  px: 3.5,
  py: 2.5,
  background: "none",
  border: "1px solid currentColor",
  borderRadius: "default",
  color: "inherit",
  fontSize: "inherit",
  fontFamily: "inherit",
  lineHeight: "inherit",
  textAlign: "center",
  textDecoration: "none",
  whiteSpace: "nowrap",
  cursor: "pointer",
  appearance: "none",
  "&:disabled, &[aria-disabled]": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
});

const Button = React.forwardRef(
  ({ href, to, icon, children, ..._props }, ref) => {
    const url = to || href;
    const props = { ref, "data-button": true, ..._props };

    if (url) {
      const isRelative = /^(\/(?!\/)|#)/.test(url);
      if (isRelative) {
        props.as = GatsbyLink;
        props.to = url;
      } else {
        props.as = "a";
        props.href = url;
        props.rel = "noreferrer noopener";
        props.target = "_blank";
      }
    }

    return (
      <StyledButton {...props}>
        {icon && (
          <React.Fragment>
            <Icon icon={icon} />
            &ensp;
          </React.Fragment>
        )}
        {children}
      </StyledButton>
    );
  }
);

export default Button;
