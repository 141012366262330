import emotionStyled from "@emotion/styled";
import memoize from "@emotion/memoize";
import isPropValid from "@emotion/is-prop-valid";
import { css, get } from "theme-ui";
import merge from "deepmerge";

const defaults = {
  systemProps: [],
  baseStyles: {},
  forwardProps: [],
  themeKey: "variants",
};

export function styled(component, options) {
  const { forwardProps, themeKey, defaultVariant } = Object.assign(
    {},
    defaults,
    options
  );

  const shouldForwardProp = createShouldForwardProp(forwardProps);
  const parseThemeProps = createParseThemeProps({ defaultVariant, themeKey });

  return (...args) =>
    emotionStyled(component || "div", { shouldForwardProp })(
      compose(args.concat(parseThemeProps))
    );
}

function compose(args) {
  return props =>
    css(
      merge.all(args.flatMap(el => (typeof el === "function" ? el(props) : el)))
    );
}

function createParseThemeProps({ defaultVariant, themeKey }) {
  return props => [
    props.__css || {},
    mergeVariants({
      variant: props.variant || defaultVariant,
      theme: props.theme,
      __themeKey: props.__themeKey || themeKey,
    }),
    props.sx || {},
  ];
}

function createShouldForwardProp(forwardProps) {
  const re = new RegExp(`^(?:${forwardProps.join("|")})$`);
  return memoize(prop => isPropValid(prop) || re.test(prop));
}

function mergeVariants({ variant, theme, __themeKey }) {
  const variants = Array.isArray(variant) ? variant : [variant];
  return merge.all(
    variants.filter(Boolean).map(variant => {
      return get(theme, `${__themeKey}.${variant}`, get(theme, variant, {}));
    })
  );
}
