import React from "react";
import PropTypes from "prop-types";

import { useCheckout } from "state/shopify";
import { getSlug } from "utils/formatting";
import { parseTemplateString } from "utils/templateStrings";

import Box from "components/Box";
import Link from "components/Link";
import Navigation from "components/Navigation";

const propTypes = {
  siteTitle: PropTypes.string,
};

const defaultProps = {
  siteTitle: ``,
};

const style = {
  wrapper: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: ["space-between", "start"],
    width: "xl",
    maxWidth: "100%",
    mx: "auto",
    mb: 5,
    py: 3,
    px: [3, 4.5],
  },
  nameplate: {
    mr: [0, 4],
    "& span:nth-of-type(even)": {
      fontStretch: "expanded",
    },
  },
};

// TODO
// investigate redundant renders
const Header = ({ navItems }) => {
  const checkout = useCheckout();
  const items = React.useMemo(() => {
    return getNavItems(navItems, {
      parseTitle: parseTemplateString,
      actions: { checkout },
    });
  }, [navItems, checkout]);

  return (
    <Box sx={style.wrapper} as="header" role="banner">
      <Link to="/" variant="banner" sx={style.nameplate}>
        <span>Johnny</span>
        &nbsp;
        <span>Moses</span>
      </Link>
      <Navigation sx={style.navigation} label="Main Navigation" items={items} />
    </Box>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = defaultProps;

export default Header;

function getNavItems(items, options) {
  const nodePrefix = "SanityMenuItem";

  return items.map(item => getItem(item));

  function getItem(item, level) {
    const {
      __typename,
      _key,
      meta,
      items,
      to,
      type: separatorType,
      action,
      ...rest
    } = item;

    level = isNaN(Number(level)) ? 0 : level;

    let type = __typename.slice(nodePrefix.length).toLowerCase();
    if (type === "internallink" || type === "externallink") {
      type = "link";
    }

    let { title, subtitle, icon } = meta || {};
    if (options.parseTitle) {
      title = options.parseTitle(title);
    }

    let onClick = null;
    if (options.actions && options.actions[action]) {
      onClick = options.actions[action];
    }

    let children = null;
    if (items) {
      children = items.map(item => getItem(item, level + 1));
    }

    return {
      items: children,
      icon,
      key: _key,
      level,
      subtitle,
      title,
      to: to ? getSlug(to) : null,
      type,
      ...(onClick ? { onClick } : {}),
      ...rest,
    };
  }
}
