import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { styled } from "style";

const StyledLink = styled("a", {
  themeKey: "links",
  forwardProps: ["to", "state"],
})();

const Link = React.forwardRef(({ href, to, ..._props }, ref) => {
  const url = to || href;
  const props = { ref, ..._props };
  const isRelative = /^(\/(?!\/)|#)/.test(url);

  if (isRelative) {
    props.as = GatsbyLink;
    props.to = url;
  } else {
    props.as = "a";
    props.href = url;
    props.rel = "noreferrer noopener";
    props.target = "_blank";
  }

  return <StyledLink ref={ref} variant="styles.a" {...props} />;
});

export default Link;
