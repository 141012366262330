import React from "react";
import memoize from "@emotion/memoize";
import weakMemoize from "@emotion/weak-memoize";

export * from "./theme";
export * from "./styled";

const getProps = test => {
  return memoize(props => {
    const next = {};
    for (let key in props) {
      if (test(key || "")) {
        next[key] = props[key];
      }
    }

    return next;
  });
};

const filterProps = weakMemoize(propNames => {
  const test = new RegExp(`^(${propNames.join("|")})$`);
  return {
    getFilteredProps: getProps(p => test.test(p)),
    getRemainingProps: getProps(p => !test.test(p)),
  };
});

const useFilterProps = propNames => {
  return React.useMemo(() => {
    const test = new RegExp(`^(${propNames.join("|")})$`);
    return {
      getFilteredProps: getProps(p => test.test(p)),
      getRemainingProps: getProps(p => !test.test(p)),
    };
  }, [propNames]);
};

function getThemeProps(args) {
  const { style, themeKey, variant: defaultVariant } = args;
  return (key, props) => {
    let { variant, ...rest } = props || {};
    if (!variant) {
      variant = defaultVariant || "default";
    }

    if (Array.isArray(variant)) {
      variant = key
        ? [`${variant[0]}.${key}`].concat(variant.slice(1))
        : variant;
    } else {
      variant = key ? `${variant}.${key}` : variant;
    }

    return {
      __themeKey: themeKey,
      __css: style && style[key] ? style[key] : {},
      variant,
      ...rest,
    };
  };
}

export { getThemeProps, filterProps, useFilterProps };
