import { get } from "theme-ui";

import * as navigation from "./navigation";
import * as buttons from "./buttons";

export { buttons, navigation };

export const cover = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "block",
};

export const visuallyHidden = {
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  border: "0",
  clip: "rect(0 0 0 0)",
  overflow: "hidden",
};

export const focus = {
  outline: "none",
  boxShadow: theme => `0 0 0 3px ${get(theme, "colors.focusRing")}`,
  transition: "box-shadow 200ms",
};

export const truncate = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  overflow: "hidden",
};

export const links = {
  banner: {
    display: "block",
    py: [3, 2],
    px: 3,
    fontSize: 2,
    color: "text",
    fontFamily: "heading",
    fontWeight: 400,
    textDecoration: "none",
    whiteSpace: "nowrap",
    userSelect: "none",
    ":hover": {
      bg: "brick.50",
    },
  },

  nav: {
    display: "inline-block",
    p: 2,
    color: "text",
    textDecoration: "none",
    ":hover, :focus": {
      textDecoration: "underline",
      color: "focus",
    },
  },

  plain: {
    color: "inherit",
    textDecoration: "none",
    ":focus, :hover, :active": {
      textDecoration: "underline",
    },
  },

  cover: {
    "::after": {
      ...cover,
      content: "''",
    },
    ":focus": {
      boxShadow: "none",
    },
    ":focus::after": {
      variant: "focus",
    },
  },
};

export const text = {
  eyebrow: {
    color: "textFaint",
    letterSpacing: "0.025em",
    textTransform: "uppercase",
  },

  display1: {
    fontFamily: "heading",
    fontWeight: 300,
    fontSize: 5,
    lineHeight: "heading",
    mt: 0,
    mb: 3,
  },

  display2: {
    fontFamily: "body",
    fontSize: 4,
    fontWeight: 200,
    lineHeight: "heading",
    mb: 3,
  },

  small: {
    fontSize: 1,
  },

  strong: {
    fontWeight: "bold",
  },

  plain: {
    fontWeight: "normal",
    fontSize: 2,
    fontFamily: "body",
    color: "text",
  },

  h3: {
    fontSize: 3,
    fontWeight: 700,
    lineHeight: "heading",
  },

  sectionHeading: {
    minor: {
      mb: 3,
      px: 4,
      fontFamily: "heading",
      fontWeight: 400,
    },
    major: {
      mb: 3,
      fontFamily: "heading",
      fontSize: 3,
      fontWeight: 800,
      fontStretch: "expanded",
    },
  },
};

export const forms = {
  counter: {
    default: {
      input: {
        py: 2,
      },
      button: {
        px: 2,
      },
      skeleton: {
        width: 92,
        height: 42,
      },
    },
    plain: {
      wrapper: {
        border: "none",
      },
    },
  },

  tiles: {
    skeleton: {
      width: "sm",
      maxWidth: "100%",
      height: 83,
    },
  },
};

export const container = {
  width: "100%",
  maxWidth: "xl",
  mx: "auto",
};

export const cards = {
  post: {
    container: {
      color: "turquoise.900",
    },
    eyebrow: {
      color: "turquoise.500",
    },
  },
  event: {
    container: {
      color: "fuchsia.900",
    },
    eyebrow: {
      color: "fuchsia.500",
    },
  },
  product: {
    container: {
      display: ["grid", null, "block"],
      gridTemplateColumns: "clamp(0px, 200px, 33%) minmax(0px, 100%)",
      gridAutoRows: "minmax(0px, max-content)",
      alignContent: "start",
      boxShadow: "none",
      borderRadius: 0,
      textAlign: ["left", null, "center"],
      ":focus-within": {
        variant: "focus",
      },
      "a:focus": {
        boxShadow: "none",
      },
      "& > *": {
        minHeight: 0,
      },
    },
    image: {
      // width: ["200px", null, "auto"],
      // maxWidth: ["50%", null, "none"],
      // flex: "0 0 200px",
      gridColumn: 1,
      gridRow: "1 / 4",
      mt: -3,
      mx: 0,
    },
    eyebrow: {
      gridColumn: 2,
    },
    heading: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      fontSize: [2, null, 1],
      lineHeight: "heading",
      fontWeight: "normal",
      height: [
        t => t.fontSizes[2] * t.lineHeights.heading * 2,
        null,
        t => t.fontSizes[1] * t.lineHeights.heading * 2,
      ],
      gridColumn: 2,
    },
    body: {
      mb: 0,
      gridColumn: 2,
    },
  },
};
