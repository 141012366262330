import React from "react";

import { useCartCount } from "../state/shopify";

function getParser(templateVars) {
  const test = new RegExp(
    `{{\\s*${Object.keys(templateVars).join("|")}\\s*}}`,
    "g"
  );

  return function parser(str) {
    if (!str || typeof str !== "string") {
      return str;
    }

    let matches = str.match(test);
    if (!matches) {
      return str;
    }

    return matches.reduce(
      ([pieces, col], match, index, arr) => {
        const before = str.slice(col, str.indexOf(match));
        const tag = match.replace(/(?:^{{|}}$)/g, "");
        const component = React.createElement(templateVars[tag], {
          key: `${col}${tag}`,
        });

        const next = [
          pieces.concat([before, component]),
          before.length + match.length,
        ];

        if (index + 1 < arr.length) {
          return next;
        }

        return next[0].concat(str.slice(next[1], str.length));
      },
      [[], 0]
    );
  };
}

function CartCount(props) {
  const count = useCartCount();
  return <React.Fragment {...props}>{count}</React.Fragment>;
}

const templateVars = {
  cartCount: CartCount,
};

const parseTemplateString = getParser(templateVars);

export { parseTemplateString, getParser };
