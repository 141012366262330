import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { css, get } from "theme-ui";
import merge from "deepmerge";

const pulseAnimation = keyframes({
  from: { opacity: 0.025 },
  to: { opacity: 0.1 },
});

const baseStyles = {
  display: "block",
};

const skeletonStyles = {
  bg: "currentColor",
  animation: "1.5s linear infinite alternate",
  animationName: pulseAnimation,
};

const Skeleton = styled("span")(props => {
  const { __css, __themeKey, sx, theme, variant } = props;
  const variants = Array.isArray(variant) ? variant : [variant];

  const variantStyle = variants.reduce((acc, variant) => {
    const keys = [
      `${__themeKey}.${variant}.skeleton`,
      `${__themeKey}.skeleton`,
      `${__themeKey}.${variant}`,
      `${variant}`,
    ];

    let style;
    let i = -1;
    while (!style && i + 1 < keys.length) {
      style = get(theme, keys[++i]);
    }

    return merge(acc, style || {});
  }, {});

  return css(
    merge.all([baseStyles, __css || {}, variantStyle, sx || {}, skeletonStyles])
  );
});

export default Skeleton;
