import React from "react";
import { useThemeUI, get } from "theme-ui";

export default function useMatchMedia(query, options = {}) {
  const { default: defaultMatches = false, callback = null } = { ...options };
  const { theme } = useThemeUI();

  let queryString;
  if (typeof query === "function") {
    queryString = query({ theme, get });
  } else if (typeof query === "string" || !isNaN(Number(query))) {
    const bp = get(theme, `breakpoints.${query}`, null);
    queryString = bp ? `(min-width: ${bp})` : query;
  }

  if (typeof queryString !== "string") {
    throw new Error(
      `useMatchMedia requires a media query string: received ${typeof queryString}.`
    );
  }

  const [match, setMatch] = React.useState(() => {
    if (isClientSide()) {
      return matchMedia(queryString).matches;
    }

    return defaultMatches;
  });

  React.useEffect(() => {
    if (!isClientSide()) {
      return undefined;
    }

    let isActive = true;
    const queryList = matchMedia(queryString);
    update();
    addListener(queryList, "change", update);

    function update() {
      if (isActive) {
        if (callback) {
          callback(queryList.matches);
        } else {
          setMatch(queryList.matches);
        }
      }
    }

    return () => {
      isActive = false;
      removeListener(queryList, "change", update);
    };
  }, [callback, queryString]);

  return match;
}

function isClientSide() {
  return typeof window !== "undefined";
}

function matchMedia(args) {
  if (isClientSide()) {
    return window.matchMedia(args);
  }
}

/**
 * MediaQueryList.addListener is deprecated in favor of EventTarget's
 * addEventListener, but for IE and iOS < 14 we need to stick with addListener
 */
function addListener(queryList, ...args) {
  if (typeof queryList.addEventListener === "function") {
    queryList.addEventListener(...args);
  } else {
    queryList.addListener(args[1]);
  }
}
function removeListener(queryList, ...args) {
  if (typeof queryList.removeEventListener === "function") {
    queryList.removeEventListener(...args);
  } else {
    queryList.removeListener(args[1]);
  }
}
