import React from "react";
import createStore from "zustand";
import { persist } from "zustand/middleware";
import shallow from "zustand/shallow";

import { formatPrice } from "utils/formatting";

function isBrowser() {
  return typeof window !== "undefined";
}

let client;

const useStore = createStore(
  persist(
    (set, get) => ({
      status: {
        isInitializing: false,
        isInitialized: false,
        isBusy: false,
      },
      id: "",
      checkout: {},
      initialize: async () => {
        const { status, setStatus, getCheckout } = get();
        if (!status.isInitialized && !status.isInitializing) {
          setStatus({ isInitializing: true });
          const checkout = await getCheckout();
          set(prev => ({
            checkout,
            status: {
              ...prev.status,
              isInitialized: true,
              isInitializing: false,
            },
          }));
        }
      },
      getCheckout: async () => {
        let { checkout, id } = get();
        if (checkout.id) {
          return checkout;
        }

        if (!isBrowser()) {
          return { lineItems: [] };
        }

        const client = await getClient();

        if (id) {
          checkout = await client.checkout.fetch(id);
          if (checkout) {
            if (checkout.completedAt) {
              set({ id: "" });
            } else {
              set({ checkout });
              return checkout;
            }
          }
        }

        checkout = await client.checkout.create();
        set({ checkout });
        return checkout;
      },
      addLineItem: async item => {
        const { setStatus, getCheckout } = get();
        setStatus({ isBusy: true });
        const checkout = await getCheckout();
        const client = await getClient();
        const next = await client.checkout.addLineItems(checkout.id, [item]);
        set(prev => ({
          checkout: next,
          status: {
            ...prev.status,
            isBusy: false,
          },
        }));
      },
      removeLineItem: async id => {
        const { setStatus, getCheckout } = get();
        setStatus({ isBusy: true });
        const checkout = await getCheckout();
        const client = await getClient();
        const next = await client.checkout.removeLineItems(checkout.id, [id]);
        set(prev => ({
          checkout: next,
          status: {
            ...prev.status,
            isBusy: false,
          },
        }));
      },
      updateLineItems: async items => {
        const { getCheckout, setStatus } = get();
        setStatus({ isBusy: true });
        const checkout = await getCheckout();
        const client = await getClient();
        const next = await client.checkout.updateLineItems(
          checkout.id,
          [].concat(items)
        );
        set(prev => ({
          checkout: next,
          status: { ...prev.status, isBusy: false },
        }));
      },
      setStatus: status => {
        set(prev => ({ status: { ...prev.status, ...status } }));
      },
    }),
    {
      name: "johnnymoses-store",
      partialize: state => ({ id: state.checkout?.id ?? "" }),
    }
  )
);

async function getClient() {
  if (client) {
    return client;
  }

  const Shopify = await import("shopify-buy").then(module => module.default);

  client = Shopify.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
    domain: process.env.GATSBY_SHOPIFY_STORE,
  });

  return client;
}

function initialize() {
  const { status, initialize } = useStore.getState();
  if (!status.isInitialized) {
    initialize();
  }
}

function selectAddLineItem(state) {
  return state.addLineItem;
}

function selectRemoveLineItem(state) {
  return state.removeLineItem;
}

function selectUpdateLineItems(state) {
  return state.updateLineItems;
}

function selectCheckoutLineItems(state) {
  return state.checkout?.lineItems;
}

function selectCheckoutTotals(state) {
  return {
    subtotal: state.checkout?.subtotalPriceV2,
    tax: state.checkout?.totalTaxV2,
    total: state.checkout?.totalPriceV2,
  };
}

function selectCheckoutURL(state) {
  return state.checkout?.webUrl;
}

function selectStatus(state) {
  return state.status;
}

/**
 * add item and update checkout.
 * Expects an object of the shape { variantId, quantity[,  customAttributes]},
 * where `variantId` is the encoded id returned by the storefront
 */
function useAddItemToCart() {
  return useStore(selectAddLineItem);
}

/**
 * remove item and update checkout
 */
function useRemoveItemFromCart() {
  return useStore(selectRemoveLineItem);
}

/**
 * update existing checkout line items
 */
function useUpdateItemsInCart() {
  return useStore(selectUpdateLineItems);
}

/**
 * get count of current checkout line items
 */
function useCartCount() {
  initialize();
  const lineItems = useStore(selectCheckoutLineItems);
  return lineItems
    ? lineItems.reduce((memo, item) => memo + item.quantity, 0)
    : 0;
}

/**
 * get checkout line items
 */
function useCartItems() {
  initialize();
  return useStore(selectCheckoutLineItems) || [];
}

/**
 * get formatted subbtotal, total and tax
 */
function useCartTotals() {
  initialize();
  const totals = useStore(selectCheckoutTotals, shallow);
  const NA = "---";
  return {
    subtotal: totals.subtotal ? formatPrice(totals.subtotal) : NA,
    tax: totals.tax ? formatPrice(totals.tax) : NA,
    total: totals.total ? formatPrice(totals.total) : NA,
  };
}

/**
 * returns a function that navigates to the checkout page
 */
function useCheckout() {
  initialize();
  const url = useStore(selectCheckoutURL);
  return React.useCallback(() => {
    window.open(url);
  }, [url]);
}

/**
 * get checkout status
 */
function useCheckoutStatus() {
  return useStore(selectStatus);
}

/**
 * retrieve lineitem image
 */
function useGetVariantImage() {
  async function getVariantImage(variant) {
    const client = await getClient();
    const alt = variant.image.altText;
    const src = await client.image.helpers.imageForSize(variant.image, {
      maxWidth: 200,
      maxHeight: 200,
    });
    return { src, alt };
  }

  return getVariantImage;
}

export {
  useAddItemToCart,
  useCartCount,
  useCartItems,
  useCartTotals,
  useCheckout,
  useCheckoutStatus,
  useGetVariantImage,
  useRemoveItemFromCart,
  useUpdateItemsInCart,
};
