// to do
// reference sanity routes somehow, here
// maybe through context?
// should this be a hook?
// we also want a getSlug function to use in gatsby-node, which can't use a hook
// maybe two functions?
function getSlug(props, fallback) {
  const { _type, content } = props;
  const slug = content?.main?.slug?.current;
  if (!slug) {
    return fallback;
  }

  switch (_type) {
    case "collection": {
      return `/store/collection/${slug}`;
    }
    case "product": {
      return `/store/product/${slug}`;
    }
    case "artwork": {
      return `/artwork/${slug}`;
    }
    case "page": {
      return `/${slug}`;
    }
    default: {
      return `/${slug}`;
    }
  }
}

function formatPrice(price, quantity) {
  if (!price) {
    return null;
  }

  quantity = isNaN(Number(quantity)) ? 1 : quantity;

  let amount,
    currencyCode = "USD";
  if (typeof price === "string") {
    amount = Number(price);
  } else {
    amount = price.amount;
    if (price.currencyCode) {
      currencyCode = price.currencyCode;
    }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
  });

  return formatter.format(amount * quantity);
}

/**
 * @see https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
 */
function slugify(str) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

function upperFirst(str) {
  if (typeof str !== "string") {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

function lowerFirst(str) {
  if (typeof str !== "string") {
    return str;
  }

  return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Adapted from Sindre Sorhus's camelcase
 * @see https://github.com/sindresorhus/camelcase/
 *
 * That package's Unicode escapes add some bloat to our compiled package
 * (since IE doesn't support Unicode escapes), but since we probably don't need
 * to support non-latin characters, we're using more naive patterns.
 *
 * See PR #62 for more info
 * @see https://github.com/sindresorhus/camelcase/pull/62
 */

function preserveCamelCase(string) {
  let isLastCharLower = false;
  let isLastCharUpper = false;
  let isLastLastCharUpper = false;

  for (let i = 0; i < string.length; i++) {
    const character = string[i];

    if (
      isLastCharLower &&
      /[a-zA-Z]/.test(character) &&
      character.toUpperCase() === character
    ) {
      string = string.slice(0, i) + "-" + string.slice(i);
      isLastCharLower = false;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = true;
      i++;
    } else if (
      isLastCharUpper &&
      isLastLastCharUpper &&
      /[a-zA-Z]/.test(character) &&
      character.toLowerCase() === character
    ) {
      string = string.slice(0, i - 1) + "-" + string.slice(i - 1);
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = false;
      isLastCharLower = true;
    } else {
      isLastCharLower =
        character.toLowerCase() === character &&
        character.toUpperCase() !== character;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper =
        character.toUpperCase() === character &&
        character.toLowerCase() !== character;
    }
  }

  return string;
}

function camelCase(input) {
  if (!(typeof input === "string" || Array.isArray(input))) {
    throw new TypeError("Expected the input to be `string | string[]`");
  }

  if (Array.isArray(input)) {
    input = input
      .map(x => x.trim())
      .filter(x => x.length)
      .join("-");
  } else {
    input = input.trim();
  }

  if (input.length === 0) {
    return "";
  }

  const hasUpperCase = input !== input.toLowerCase();

  if (hasUpperCase) {
    input = preserveCamelCase(input);
  }

  input = input
    .replace(/^[_.\- ]+/, "")
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (_, p1) => p1.toUpperCase())
    .replace(/\d+(\w|$)/g, m => m.toUpperCase());

  return input;
}

export { camelCase, formatPrice, getSlug, lowerFirst, slugify, upperFirst };
