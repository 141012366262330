import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Accessibility from "components/Accessibility";
import Box from "components/Box";
import Footer from "components/Footer";
import Head from "components/Head";
import Header from "components/Header";
import Notifications from "components/Notifications";
import SkipLink from "components/SkipLink";

const propTypes = {
  children: PropTypes.node.isRequired,
};

const style = {
  wrapper: {
    display: "flex",
    maxWidth: "100vw",
    minHeight: "100vh",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteGlobal {
        content {
          metaInformation {
            title
          }
        }
      }
      mainMenu: sanityMenu(slug: { current: { eq: "main" } }) {
        items {
          ...menuItemInternalLink
          ...menuItemExternalLink
          ...menuItemDropdown
          ...menuItemButton
          ...menuItemSeparator
        }
      }
      footerMenu: sanityMenu(slug: { current: { eq: "footer" } }) {
        items {
          ...menuItemInternalLink
          ...menuItemExternalLink
          ...menuItemDropdown
          ...menuItemButton
          ...menuItemSeparator
        }
      }
    }
  `);

  const headerNavItems = data.mainMenu?.items || [];
  const footerNavItems = data.footerMenu?._rawItems || [];
  const siteTitle = data.sanitySiteGlobal.content.metaInformation.title;

  return (
    <React.Fragment>
      <Head />
      <Accessibility />
      <SkipLink to="#main">Skip to main content</SkipLink>
      <Box sx={style.wrapper}>
        <Header navItems={headerNavItems} siteTitle={siteTitle} />
        <main id="main" role="main">
          {children}
        </main>
        <Footer navItems={footerNavItems} mt="auto" />
        <Notifications />
      </Box>
    </React.Fragment>
  );
};

Layout.propTypes = propTypes;

export default Layout;

export const menuItemFragments = graphql`
  fragment menuItemInternalLink on SanityMenuItemInternalLink {
    _key
    __typename
    meta {
      title
      subtitle
      icon
    }
    to {
      ... on SanityPage {
        _type
        content {
          main {
            slug {
              current
            }
          }
        }
      }
      ... on SanityProduct {
        _type
        content {
          main {
            slug {
              current
            }
          }
        }
      }
      ... on SanityCollection {
        _type
        content {
          main {
            slug {
              current
            }
          }
        }
      }
      ... on SanityArtwork {
        _type
        content {
          main {
            slug {
              current
            }
          }
        }
      }
    }
  }

  fragment menuItemExternalLink on SanityMenuItemExternalLink {
    _key
    __typename
    meta {
      title
      subtitle
      icon
    }
    href
  }

  fragment menuItemDropdown on SanityMenuItemDropdown {
    _key
    __typename
    meta {
      title
      subtitle
      icon
    }
    items {
      ...menuItemInternalLink
      ...menuItemExternalLink
      ...menuItemSeparator
      ...menuItemButton
    }
  }

  fragment menuItemButton on SanityMenuItemButton {
    _key
    __typename
    meta {
      title
      subtitle
      icon
    }
    action
  }

  fragment menuItemSeparator on SanityMenuItemSeparator {
    _key
    __typename
    type
  }
`;
