import React from "react";
import { useSpring, animated } from "@react-spring/web";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyboardFocus } from "@zendeskgarden/container-keyboardfocus";
import { get } from "@theme-ui/css";

import useAnimateScroll from "hooks/useAnimateScroll";

import { useMenuState } from "./context";

import Box from "components/Box";
import Button from "components/Button";
import VisuallyHidden from "components/VisuallyHidden";

function isClientSide() {
  return typeof window !== "undefined";
}

const AnimatedBox = animated(Box);
const AnimatedButton = animated(Button);

const style = {
  nav: {
    position: "relative",
    zIndex: "modal",
    WebkitOverflowScrolling: "touch",
    // px: 3,
  },

  overlay: {
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    bg: "link.50",
    borderRadius: "default",
    transition: "500ms ease-in-out",
    transitionProperty: "background-color, border-radius",
    transformOrigin: "left top",
    "&[data-expanded='true']": {
      bg: "gray.100",
      borderRadius: 0,
    },
  },

  content: {
    position: "fixed",
    zIndex: 0,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "none",
    overflow: "hidden",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    pt: theme =>
      `calc(${get(theme, "space.3") * 2}px + 21px + ${get(
        theme,
        "space.4"
      )}px)`,
    px: 3,
    pb: 6,
    outline: "none",
  },

  toggle: {
    zIndex: 1,
    bg: "focus.50",
  },

  svg: {
    display: "inline-block",
    height: "1em",
    width: "1em",
    verticalAlign: "-0.15em",
    fontSize: "inherit",
    color: "inherit",
    overflow: "visible",
    path: {
      vectorEffect: "non-scaling-stroke",
      strokeWidth: 1,
      fill: "none",
      stroke: "currentColor",
    },
  },
};

const Toggle = React.forwardRef(({ children, isOpen, ...props }, ref) => {
  const { getFocusProps, keyboardFocused } = useKeyboardFocus();
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  const [animatedStyles] = useSpring(
    {
      from: {
        strokeDasharray:
          isOpen && isMounted.current ? "1.35em 12.1em" : "1.35em 12.1em",
        strokeDashoffset: isOpen && isMounted.current ? "-5.15em" : "8.2em",
      },
      to: {
        strokeDasharray: "1em 12.1em",
        strokeDashoffset: isOpen ? "0em" : "3.9em",
      },
      reverse: isOpen,
      reset: true,
      immediate: !isMounted.current,
      config: {
        tension: 200,
        friction: 18,
      },
    },
    [isOpen]
  );

  return (
    <AnimatedButton
      __themeKey="navigation"
      ref={ref}
      sx={style.toggle}
      variant="toggle"
      {...(keyboardFocused ? { "data-focused": true } : {})}
      {...getFocusProps(props)}
    >
      <VisuallyHidden>{isOpen ? "open " : "close "}</VisuallyHidden>
      menu &thinsp;
      <AnimatedBox
        aria-hidden
        as="svg"
        style={animatedStyles}
        sx={style.svg}
        variant="icons.default"
        viewBox="0 0 144 144"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Box
          as="path"
          d="M0 36C0 36 108 36 144 36C183.8 36 216 3.8 216 -36C216 -115.5 151.5 -180 72 -180C0 -180 -36 -138.4 -36 -87C-36 -54.4 -23.1 -23.1 0 0C38.6 38.6 115.9 115.9 154.5 154.5C170.8 170.8 192.9 180 216 180C255.8 180 288 147.8 288 108C288 68.2 255.8 36 216 36C162 36 -216 36 -216 36H0Z"
        />
        <Box
          as="path"
          d="M0 108C0 108 108 108 144 108C183.8 108 216 140.2 216 180C216 259.5 151.5 324 72 324C0 324 -36 282.4 -36 231C-36 198.4 -23.1 167.1 0 144C38.6 105.4 115.9 28.1 154.5 -10.5C170.8 -26.8 192.9 -36 216 -36C255.8 -36 288 -3.8 288 36C288 75.8 255.8 108 216 108C162 108 -216 108 -216 108H0Z"
        />
      </AnimatedBox>
    </AnimatedButton>
  );
});

const MobileMenu = ({ children, ...props }) => {
  const [isOpen, setState] = useMenuState();
  const animateScroll = useAnimateScroll();
  const button = React.useRef();
  const nav = React.useRef();
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  React.useEffect(() => {
    if (!isClientSide()) {
      return;
    }

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    function handleKeyDown(event) {
      if (isOpen && event.key === "Escape") {
        event.stopPropagation();
        setState(false);
      }
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, setState]);

  const [{ display, matrix, opacity, rotate3d }] = useSpring(
    () => ({
      from: {
        display: "none",
        matrix: [1, 0, 0, 1, 0, 0],
        rotate3d: [1, 1, 1, "0deg"],
        opacity: 0,
      },
      to: async animate => {
        if (!button.current || !isMounted.current) {
          return;
        }

        if (isOpen) {
          const rect = button.current.getBoundingClientRect();
          await Promise.all([
            animateScroll(),
            animate({
              matrix: [
                window.innerWidth / rect.width,
                0,
                0,
                window.innerHeight / rect.height,
                0 - (window.pageXOffset + rect.left),
                0 - (window.pageYOffset + rect.top),
              ],
            }),
          ]);
          await animate({ display: "block" });
          await animate({ opacity: 1 });
        } else {
          await animate({ opacity: 0 });
          await animate({ matrix: [1, 0, 0, 1, 0, 0] });
          await animate({ display: "none" });
        }
      },
      config: key => ({
        clamp: key === "opacity",
        tension: 300,
        friction: 30,
      }),
    }),
    [isOpen]
  );

  if (!isMounted.current) {
    return null;
  }

  return (
    <Box as="nav" ref={nav} __css={style.nav} {...props}>
      <FocusLock disabled={!isOpen} returnFocus>
        <AnimatedBox
          style={{ matrix, rotate3d }}
          sx={style.overlay}
          data-expanded={isOpen}
        />
        <Toggle
          aria-expanded={isOpen}
          isOpen={isOpen}
          onClick={setState}
          ref={button}
        />
        <RemoveScroll enabled={isOpen} forwardProps>
          <AnimatedBox style={{ display, opacity }} sx={style.content}>
            {children}
          </AnimatedBox>
        </RemoveScroll>
      </FocusLock>
    </Box>
  );
};

export default MobileMenu;
