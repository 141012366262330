export const globalStyles = {
  "*, *::before, *::after": {
    boxSizing: "border-box",
    borderStyle: "solid",
    borderWidth: "0px",
    borderColor: "currentColor",
  },
  "*:focus": {
    outline: "none",
    boxShadow: "focus",
    transition: "box-shadow 200ms",
  },
  html: {
    backgroundColor: "background",
    fontFamily: "body",
    lineHeight: "body",
    WebkitTextSizeAdjust: "100%",
  },
  body: {
    minHeight: "100vh",
    margin: 0,
    color: "text",
    fontFamily: "inherit",
    fontSize: 2,
    fontWeight: "inherit",
    lineHeight: "inherit",
    textRendering: "optimizeSpeed",
  },
  "button, input, optgroup, select, textarea": {
    margin: 0,
    padding: 0,
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: "inherit",
    color: "inherit",
  },
  "button, select": {
    textTransform: "none",
  },
  "button, [type='button'], [type='reset'], [type='submit']": {
    WebkitAppearance: "button",
  },
  "button, [role='button']": {
    cursor: "pointer",
  },
  button: {
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
  "::-moz-focus-inner": {
    borderStyle: "none",
    padding: 0,
  },
  ":-moz-ui-invalid": {
    boxShadow: "none",
  },
  "h1, h2, h3, h4, h5, h6": {
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "heading",
  },
  "blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre": {
    margin: 0,
  },
  "ol, ul": {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  a: {
    color: "inherit",
    textDecoration: "inherit",
  },
  "audio, canvas, embed, iframe, img, object, svg, video": {
    display: "block",
    maxWidth: "100%",
    verticalAlign: "middle",
  },
  strong: {
    fontWeight: "bold",
  },
  "@media (prefers-reduced-motion: reduce)": {
    "*, *::before, *::after": {
      animationDuration: "1ms !important",
      animationIterationCount: "1 !important",
      transitionDuration: "1ms !important",
      scrollBehavior: "auto !important",
    },
  },
};
