import React from "react";

import { NavigationContextProvider } from "./context";

import Item from "./Item";
import List from "./List";

const defaultProps = {
  breakpoint: "navbar",
};

const Navigation = ({ breakpoint, items, label }) => (
  <NavigationContextProvider breakpoint={breakpoint}>
    <List label={label}>
      {items.map(item => (
        <Item {...item} />
      ))}
    </List>
  </NavigationContextProvider>
);

Navigation.defaultProps = defaultProps;

Navigation.Item = Item;
Navigation.List = List;

export default Navigation;
