import React from "react";
import { Global } from "@emotion/react";
import { css } from "theme-ui";

import { fontFaceDeclarations } from "assets/fonts";
import { globalStyles } from "style/global";

function GlobalStyles() {
  return (
    <Global
      styles={[
        ...fontFaceDeclarations.map(o => ({ "@font-face": o })),
        {
          ":root": {
            "--reach-accordion": 1,
            "--reach-listbox": 1,
            "--reach-dialog": 1,
            WebkitOverflowScrolling: "touch",
          },
        },
        css(globalStyles),
      ]}
    />
  );
}

export default GlobalStyles;
