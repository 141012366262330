import React from "react";

import Box from "components/Box";
import Link from "components/Link";
import Text from "components/Text";

const getHref = ({ _type, link }) => {
  if (_type === "internalLink") {
    const slug = link.content.main.slug.current;
    return slug === "home" ? "/" : `/${slug}`;
  }

  return link;
};

const style = {
  wrapper: {
    width: "xl",
    maxWidth: "100%",
    mt: "auto",
    mx: "auto",
    px: 4,
    textAlign: "center",
  },
  nav: {
    mx: -2,
    my: 3,
  },
  list: {
    display: "flex",
    ml: 0,
    pl: 0,
    listStyle: "none",
    justifyContent: "center",
    "li + li": {
      ml: 3,
    },
  },
  copyright: {
    display: "block",
    my: 3,
    fontSize: 0,
    color: "gray.600",
  },
};

const Footer = ({ navItems, ...props }) => {
  return (
    <Box as="footer" sx={style.wrapper} {...props}>
      <Box as="nav" sx={style.nav}>
        <Box as="ul" sx={style.list}>
          {navItems.map(({ _key, title, ...item }) => (
            <li key={_key}>
              <Link to={getHref(item)} variant="nav">
                {title}
              </Link>
            </li>
          ))}
        </Box>
      </Box>
      <Text as="span" sx={style.copyright}>
        © 2003-{new Date().getFullYear()} by Johnny Moses. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
