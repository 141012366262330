import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

import { slugify } from "utils/formatting";
import { styled } from "style";

import Skeleton from "components/Skeleton";

const propTypes = {
  icon: PropTypes.string.isRequired,
};

const defaultProps = {
  variant: "default",
};

const baseStyles = {
  display: "inline-block",
  height: "1em",
  width: "1em",
  verticalAlign: "-0.15em",
  fontSize: "inherit",
  color: "inherit",
  "& *": {
    strokeWidth: 1,
    vectorEffect: "non-scaling-stroke",
  },
};

const StyledIcon = styled("span", {
  baseStyles,
  themeKey: "icons",
  forwardProps: ["fallback"],
})(baseStyles);

const iconAliases = { cd: "disc", dvd: "disc" };

const localIcons = ["brand-apple-music", "brand-spotify", "brand-youtube"];

const AsyncFeatherIcon = loadable(
  props => import(`react-feather/dist/icons/${props["data-icon"]}`),
  { cacheKey: props => props["data-icon"], ssr: false }
);

const AsyncLocalIcon = loadable(
  props => import(`./icons/${props["data-icon"]}`),
  { cacheKey: props => props["data-icon"], ssr: false }
);

const Icon = ({ icon: _icon, ...props }) => {
  if (!_icon) {
    return null;
  }

  let icon = slugify(_icon);

  if (icon in iconAliases) {
    icon = iconAliases[icon];
  }

  const isLocalIcon = localIcons.includes(icon);

  return (
    <StyledIcon
      as={isLocalIcon ? AsyncLocalIcon : AsyncFeatherIcon}
      fallback={<Skeleton __themeKey="icons" __css={baseStyles} {...props} />}
      data-icon={icon}
      aria-hidden="true"
      {...props}
    />
  );
};

Icon.propTypes = propTypes;

Icon.defaultProps = defaultProps;

export default Icon;
