import React from "react";

import { slugify } from "utils/formatting";

import Box from "components/Box";
import VisuallyHidden from "components/VisuallyHidden";

import { useNavigationBreakpoint } from "./context";

import FlyOutMenu from "./FlyOutMenu";
import MobileMenu from "./MobileMenu";

const defaultProps = {
  level: 0,
};

const style = {
  display: "flex",
  padding: 0,
  margin: 0,
  listStyle: "none",
};

const List = ({ label, ...props }) => {
  const { isMobile } = useNavigationBreakpoint();

  if (props.level === 0) {
    const id = slugify(label);

    if (isMobile) {
      return (
        <MobileMenu aria-labelledby={id}>
          <VisuallyHidden id={id}>{label}</VisuallyHidden>
          <Items {...props} />
        </MobileMenu>
      );
    }

    return (
      <nav aria-labelledby={id}>
        <VisuallyHidden id={id}>{label}</VisuallyHidden>
        <Items variant={"main"} {...props} />
      </nav>
    );
  }

  if (!isMobile) {
    return (
      <FlyOutMenu.List>
        <Items variant="flyOut" {...props} />
      </FlyOutMenu.List>
    );
  }

  return <Items variant={"default"} {...props} />;
};

List.defaultProps = defaultProps;

export default List;

function Items({ level, ...props }) {
  return (
    <Box
      as="ul"
      __css={style}
      __themeKey="navigation.list"
      variant="default"
      data-nav-level={level}
      {...props}
    />
  );
}
