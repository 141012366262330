export function get(obj, key, def, p, undef) {
  key = key && key.split ? key.split(".") : [key];
  for (p = 0; p < key.length; p++) {
    obj = obj ? obj[key[p]] : undef;
  }

  return obj === undef ? def : obj;
}

function getUniqueId() {
  let id = 0;
  return prefix => `${prefix || "UID_"}${++id}`;
}

export const uniqueId = getUniqueId();

export function isNil(value) {
  return value === null || typeof value === "undefined";
}
