import React from "react";
import { positionDefault } from "@reach/popover";

import { uniqueId } from "utils/helpers";
import useMatchMedia from "hooks/useMatchMedia";

const MENU_OPENED = "MENU_OPENED";
const MENU_CLOSED = "MENU_CLOSED";
const MENU_TOGGLED = "MENU_TOGGLED";
const MENU_ADDED = "MENU_ADDED";
const MENU_REMOVED = "MENU_REMOVED";
const ALL_CLOSED = "ALL_CLOSED";

const NavigationContext = React.createContext({
  getMenuState: () => ({}),
  addMenu: () => () => {},
});

const NavigationContextProvider = ({ children, breakpoint }) => {
  const [menus, dispatch] = React.useReducer(menuStateReducer, []);
  const mediaMatch = useMatchMedia(breakpoint);

  const getMenuState = (id, initial) => {
    const menu = menus.find(el => el.id === id);
    return menu ? menu.isExpanded : initial;
  };

  return (
    <NavigationContext.Provider
      value={{ getMenuState, dispatch, mediaMatch, breakpoint }}
      children={children}
    />
  );
};

function menuStateReducer(state, [action, payload]) {
  switch (action) {
    case MENU_ADDED: {
      const { id, level, isExpanded = false } = payload;
      return state.concat({ id, level, isExpanded });
    }
    case MENU_REMOVED: {
      const { id } = payload;
      return state.filter(el => el.id !== id);
    }
    case MENU_OPENED: {
      const { id } = payload;
      const { level } = state.find(el => el.id === id);
      return state.map(el => {
        if (el.id === id) {
          el.isExpanded = true;
        } else if (el.level >= level) {
          el.isExpanded = false;
        }
        return el;
      });
    }
    case MENU_CLOSED: {
      const { id } = payload;
      return state.map(el => {
        if (el.id === id) {
          el.isExpanded = false;
        }
        return el;
      });
    }
    case MENU_TOGGLED: {
      const { id } = payload;
      const { level, isExpanded } = state.find(el => el.id === id);
      return state.map(el => {
        if (!isExpanded && el.level >= level) {
          el.isExpanded = false;
        }
        if (el.id === id) {
          el.isExpanded = !el.isExpanded;
        }
        return el;
      });
    }
    case ALL_CLOSED: {
      return state.map(el => ({ ...el, isExpanded: false }));
    }
    default: {
      return state;
    }
  }
}

function useMenuState(options) {
  const { level = 0, initialState = false } = options || {};
  const { getMenuState, dispatch } = React.useContext(NavigationContext);
  const id = React.useRef(uniqueId("menu"));

  React.useEffect(() => {
    dispatch([MENU_ADDED, { id: id.current, isExpanded: initialState, level }]);
    const menuId = id.current;
    return () => {
      dispatch([MENU_REMOVED, { id: menuId }]);
    };
  }, [dispatch, id, initialState, level]);

  const isExpanded = getMenuState(id.current, initialState);
  const setMenuState = React.useCallback(
    state => {
      if (state === true) {
        dispatch([MENU_OPENED, { id: id.current }]);
      } else if (state === false) {
        dispatch([MENU_CLOSED, { id: id.current }]);
      } else {
        dispatch([MENU_TOGGLED, { id: id.current }]);
      }
    },
    [dispatch]
  );

  return [isExpanded, setMenuState];
}

function useNavigationBreakpoint() {
  const { mediaMatch, breakpoint } = React.useContext(NavigationContext);
  return {
    isMobile: !mediaMatch,
    isDesktop: mediaMatch,
    breakpoint,
  };
}

function useCloseMenus() {
  const { dispatch } = React.useContext(NavigationContext);
  return React.useCallback(() => {
    dispatch([ALL_CLOSED]);
  }, [dispatch]);
}

const FlyOutContext = React.createContext();

const FlyOutContextProvider = ({ children, collapseDelay = 500, level }) => {
  const [isExpanded, setExpanded] = useMenuState({ level });
  const [isActive, setActive] = React.useState(false);
  const hoverTimeout = React.useRef(null);
  const focusTimeout = React.useRef(null);
  const referenceElement = React.useRef();
  const isTouch = useMatchMedia("(hover: none)");

  const onClick = React.useCallback(
    event => {
      event.preventDefault();
      setExpanded();
      setActive(b => !b);
    },
    [setExpanded]
  );

  const onMouseEnter = React.useCallback(() => {
    if (!isTouch) {
      clearTimeoutRef(hoverTimeout);
      setExpanded(true);
      setActive(true);
    }
  }, [hoverTimeout, isTouch, setExpanded]);

  const onMouseLeave = React.useCallback(() => {
    if (!isTouch) {
      setActive(false);
      hoverTimeout.current = setTimeout(() => {
        setExpanded(false);
      }, collapseDelay);
    }
  }, [collapseDelay, isTouch, hoverTimeout, setExpanded]);

  const onBlur = React.useCallback(() => {
    clearTimeoutRef(focusTimeout);
    focusTimeout.current = setTimeout(() => {
      setExpanded(false);
      setActive(false);
    }, 0);
  }, [focusTimeout, setExpanded]);

  const onFocus = React.useCallback(() => {
    clearTimeoutRef(focusTimeout);
    setExpanded(true);
    setActive(true);
  }, [setExpanded, focusTimeout]);

  function clearTimeoutRef(ref) {
    if (ref.current) {
      clearTimeout(ref.current);
      ref.current = null;
    }
  }

  const getButtonProps = () => ({
    "aria-haspopup": true,
    "aria-expanded": isExpanded,
    onClick,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    ref: referenceElement,
  });

  const getPopoverProps = () => ({
    hidden: !isExpanded,
    position,
    targetRef: referenceElement,
  });

  const getListProps = () => ({
    "data-expanded": isExpanded,
    onMouseEnter,
    onMouseLeave,
    onBlur,
    onFocus,
    tabIndex: -1,
  });

  return (
    <FlyOutContext.Provider
      value={{
        isActive,
        isExpanded,
        getButtonProps,
        getListProps,
        getPopoverProps,
      }}
      children={children}
    />
  );
};

/**
 * @see https://www.w3.org/WAI/tutorials/menus/flyout/
 */
function useFlyOutProps() {
  const value = React.useContext(FlyOutContext);
  return value;
}

function position(targetRect, popoverRect) {
  const adjustedTargetRect = {
    bottom: targetRect.bottom + 8,
    height: targetRect.height + 16,
    left: targetRect.left + targetRect.width / 2 - popoverRect.width / 2,
    right: targetRect.right,
    top: targetRect.top - 8,
    width: targetRect.width,
    x: targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
    y: targetRect.y - 8,
  };

  return positionDefault(adjustedTargetRect, popoverRect);
}

export {
  NavigationContextProvider,
  FlyOutContextProvider,
  useFlyOutProps,
  useMenuState,
  useNavigationBreakpoint,
  useCloseMenus,
};
