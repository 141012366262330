import React from "react";
import Portal from "@reach/portal";

import {
  useCurrentNotification,
  useRemoveNotification,
} from "state/notifications";

import Box from "components/Box";
import Button from "components/Button";
import Icon from "components/Icon";
import Text from "components/Text";

const style = {
  wrapper: {
    position: "fixed",
    bottom: 3,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    width: "lg",
    maxWidth: "100%",
    p: 2,
    bg: "white",
    color: "primary.900",
    boxShadow: "xl",
    borderRadius: "default",
  },
  message: {
    width: "100%",
    overflow: "hidden",
  },
  icon: {
    mx: 3,
    flex: "0 0 auto",
  },
  button: {
    ml: 3,
    flex: "0 0 auto",
  },
};

const Notifications = () => {
  const notification = useCurrentNotification();
  const removeNotifications = useRemoveNotification();

  if (!notification) {
    return null;
  }

  const { message, actions = [], status } = notification;

  const getHandleDismiss = action => {
    return () => {
      removeNotifications();

      if (typeof action === "function") {
        action();
      }
    };
  };

  const icons = {
    success: "check-circle",
    default: "alert-triangle",
  };

  return (
    <Portal>
      <Box sx={style.wrapper}>
        <Icon icon={icons[status] || icons.default} sx={style.icon} />
        <Text as="span" sx={style.message} variant="truncate">
          {message}
        </Text>
        {actions.map(([value, action]) => (
          <Button
            key={value}
            onClick={getHandleDismiss(action)}
            children={value}
            variant={["primary", "sm"]}
            sx={style.button}
          />
        ))}
        <Button
          onClick={getHandleDismiss()}
          sx={style.button}
          variant={["plain", "sm"]}
        >
          <Icon icon="x" />
        </Button>
      </Box>
    </Portal>
  );
};

export default Notifications;
