/**
 * @link https://tulaliplushootseed.com/software-and-fonts/
 */
import lushootseedSuladWebfontWOFF2 from "./lushootseed-sulad-webfont.woff2";

const font = [
  {
    family: "Lushootseed Sulad",
    src: [{ url: lushootseedSuladWebfontWOFF2, format: "woff2" }],
    descriptors: {
      weight: "normal",
    },
  },
];

export { font };
