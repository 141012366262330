import React from "react";

import Box from "components/Box";

const style = {
  variant: "visuallyHidden",
  top: 2,
  left: 2,
  ":focus": {
    backgroundColor: "focus",
    color: "background",
    width: "auto",
    height: "auto",
    m: 0,
    p: 3,
    clip: "initial",
    overflow: "visible",
  },
};

const SkipLink = ({ to, href, ...props }) => {
  return (
    <Box as="a" href={to || href} variant="styles.a" sx={style} {...props} />
  );
};

export default SkipLink;
